<template>
  <div>
    <a-form :form="form" style="width: 60%; margin: 0 auto;">
      <a-row :gutter="[16,16]">
        <a-col :span="10" :offset="5">
          <a-form-item label="编号" >
            <a-input v-decorator="['code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="名称">
            <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item
        v-bind="buttonCol"
      >
        <a-row>
          <a-col :span="5"></a-col>
          <a-col span="6">
            <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          </a-col>
          <a-col span="6" :offset="2">
            <a-button @click="handleGoBack">返回</a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { warehouse_list_id } from '@/api/c_wms_warehouse'
import { cargo_space_update } from '@/api/c_wms_cargo_space'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      wms_warehouses: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadEditInfo(this.record)
    })
/*
    this.wmsWarehouseList()
*/
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        cargo_space_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
    })
      })
    },
    handleGetInfo () {

    },
    wmsWarehouseList () {
      warehouse_list_id().then(res => {
        this.wms_warehouses = res.data
      })
    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        console.log(data)
        this.id = data.id
        const formData = pick(data, ['name', 'code', 'wms_supervised_warehouse_id'])
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
